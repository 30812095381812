import axios from "axios";
import authHeader from "../auth-header";

const API_URL = process.env.VUE_APP_API_BASE_URL;

class McademyService {
  
  getItem(slug) {
    return axios.get(API_URL + `mcademy/${slug}`, {
      headers: authHeader(),
    });
  }

  registerUser(slug) {
    return axios.get(API_URL + `mcademy/${slug}/register`, {
      headers: authHeader(),
    });
  }

}

export default new McademyService();
