<template>
  
  <main className="py-10" v-if="data">

    <div className="mx-auto mt-8 grid max-w-3xl grid-cols-1 gap-6 sm:px-6 lg:max-w-7xl lg:grid-flow-col-dense lg:grid-cols-3">
      <div className="space-y-6 lg:col-span-2 lg:col-start-1">

        <section aria-labelledby="applicant-information-title">
          <div className="bg-white shadow sm:rounded-lg">
            <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
              <h1 className="text-lg">{{data.item.title}}</h1>
              <div v-html="data.item.description"></div>
            </div>
            <!-- <div>
              <a
                href="#"
                className="block bg-gray-50 px-4 py-4 text-center text-sm font-medium text-gray-500 hover:text-gray-700 sm:rounded-b-lg"
              >
                Read full application
              </a>
            </div> -->
          </div>
        </section>

        
      </div>

      <section aria-labelledby="timeline-title" className="lg:col-span-1 lg:col-start-3">
        <div className="bg-white px-4 py-5 shadow sm:rounded-lg sm:px-6">
          <h2 id="timeline-title" className="text-lg font-medium text-gray-900">
            Register now
          </h2>

          <div className="mt-6 flow-root">
            <ul role="list" class="text-gray-400">
              By clicking the button below, you'll be registered for this McAdemy.
            </ul>
          </div>
          <div v-if="!data.booked" className="justify-stretch mt-6 flex flex-col">
            <button
              type="button"
              @click="registerUser"
              className="inline-flex items-center justify-center rounded-md border border-transparent bg-orange-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-orange-700 focus:outline-none focus:ring-2 focus:ring-orange-500 focus:ring-offset-2"
            >
              One click registration
            </button>
            <span class='my-2'>{{confirmationMsg}}</span>
          </div>
          <div v-else className="justify-stretch mt-6 flex flex-col">
            <button
              type="button"
              className="inline-flex items-center justify-center rounded-md border border-transparent bg-orange-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-orange-700 focus:outline-none focus:ring-2 focus:ring-orange-500 focus:ring-offset-2"
            >
              Session completely booked
            </button>
          </div>
        </div>
      </section>
    </div>
  </main>

</template>

<script>
import { ref, onMounted } from "vue";
import { useRoute } from 'vue-router'
import McademyService from '../../services/mcademy/service';

export default {
  name: 'McAdemyRegistration',
  setup() {
    const data = ref(null);
    const loading = ref(true);
    const error = ref(null);
    const route = useRoute()
    const confirmationMsg = ref(null)
    const id = route.params.id;

    function fetchData() {
      loading.value = true;
      // I prefer to use fetch
      // you can use use axios as an alternative
      return McademyService.getItem(id).then(res => {
          // a non-200 response code
          if (res.status !== 200) {
            // create error instance with HTTP status text
            const error = new Error(res.statusText);
            error.json = res.json();
            throw error;
          }

          return res.data;
        })
        .then(json => {
          // set the response data
          data.value = json;
        })
        .catch(err => {
          error.value = err;
          // In case a custom JSON error response was provided
          if (err.json) {
            return err.json.then(json => {
              // set the JSON response message
              error.value.message = json.message;
            });
          }
        })
        .then(() => {
          loading.value = false;
        });
    }

    function registerUser() {
      loading.value = true;
      // I prefer to use fetch
      // you can use use axios as an alternative
      return McademyService.registerUser(id).then(res => {
          // a non-200 response code
          if (res.status !== 200) {
            // create error instance with HTTP status text
            const error = new Error(res.statusText);
            error.json = res.json();
            throw error;
          }

          return res.data;
        })
        .then(json => {
          // set the response data
          console.log(json);
          confirmationMsg.value = 'Great, registered! You should recieve an email shortly.'
        })
        .catch(err => {
          error.value = err;
          // In case a custom JSON error response was provided
          if (err.json) {
            return err.json.then(json => {
              // set the JSON response message
              error.value.message = json.message;
            });
          }
          confirmationMsg.value = 'Something went wrong, please email the trainer instead.'
        })
        .then(() => {
          loading.value = false;
        });
    }
    onMounted(() => {
      fetchData();
    });

    return {
      data,
      loading,
      error,
      registerUser,
      confirmationMsg
    };
  }
}
</script>